$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.footer {
  border-top: 1px solid $border-default;
  background-color: $background-dim;
  color: $lights-high;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topSection {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: $spacing-7 $spacing-6;

  @media (max-width: $breakpoint_m) {
    grid-template-columns: 1fr;
    padding: $spacing-6 $spacing-4;
  }
}

.newsletterSection {
  display: flex;
  width: 70%;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  margin-left: 30px;

  @media (max-width: $breakpoint_m) {
    width: 100%;
    margin-left: 0;
    margin-bottom: $spacing-6;
  }
}

.watermark {
  fill: $background-default;
  display: flex;
  width: 98%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint_s) {
    padding: $spacing-4;
    display: none;
  }
}

.newsletterTitle {
  color: $lights-high;
  @include text-h5;
}

.newsletterDescription {
  color: $lights-medium;
  padding-bottom: $spacing-4;
  @include text-body1Regular16;
}

.form {
  display: flex;
  gap: $spacing-4;
  padding-bottom: $spacing-2;

  @media (max-width: $breakpoint_s) {
    flex-direction: row;
    gap: $spacing-2;
  }
}

.subscribeInput {
  background-color: $background-default;
  border-radius: $radius-1;
  border: 1px solid $border-default;
  background: $border-default;

  @media (max-width: $breakpoint_s) {
    width: 100%;
  }
}

.subscribeButton {
  display: flex;
  width: 121px;
  height: 48px;
  padding: $spacing-2 $spacing-4;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint_s) {
    width: 50%;
  }
}

.privacyNote {
  @include text-body1Regular16;
  color: $lights-medium;
}

.privacyLink {
  color: $lights-high;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  @include text-body1Regular16;
}

.buttonGroup {
  display: flex;
  margin-top: $spacing-8;
  gap: $spacing-3;

  @media (max-width: $breakpoint_s) {
    flex-direction: row;
    gap: $spacing-2;
    width: 100%;
  }
}

.footerLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-6;
  flex-shrink: 0;
  width: 80%;

  @media (max-width: $breakpoint_m) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
}

.catActionLinks {
  display: flex;
  gap: $spacing-11;

  @media (max-width: $breakpoint_ml) {
    gap: $spacing-6;
  }

  @media (max-width: $breakpoint_s) {
    flex-direction: column;
    gap: $spacing-5;
    width: 100%;
  }
}

.footerLinkTitle {
  color: $lights-high;
  text-align: left;
  padding-bottom: $spacing-4;
  @include text-subtitle2;
}

.footerLinkList {
  color: $lights-low;
  white-space: nowrap;
  @include text-body1Regular16;
  display: flex;
  flex-direction: column;
  text-align: left;

  li {
    margin-bottom: $spacing-2;
  }
}

.footerLink {
  @media (hover: hover) {
    &:hover {
      color: $lights-high;
    }
  }
}

.socialAndStores {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 70px;

  @media (max-width: $breakpoint_m) {
    justify-content: flex-start;
  }

  @media (max-width: $breakpoint_s) {
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-4;
  }
}

.buttonIcon {
  width: 131px;
  height: 48px;
}

.socialLinks {
  display: flex;
  align-items: center;
  gap: $spacing-4;


  @media (max-width: $breakpoint_xs) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: $spacing-3 $spacing-6;

  @media (max-width: $breakpoint_s) {
    padding: $spacing-3 $spacing-4;
  }
}

.bottomSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-3;
  max-width: 1470px;

  @media (max-width: $breakpoint_l) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.textComponent {
  color: $lights-low;
}

.bottomBarLink {
  margin-left: $spacing-3;

  @media (max-width: $breakpoint_s) {
    margin-left: 0;
  }

  &:hover {
    color: $lights-high;
  }
}

.pageInfo {
  display: flex;
  padding: $spacing-1 $spacing-2;
  align-items: center;
  gap: $spacing-2;
  background: $background-dim;
  border-radius: $radius-0;
  border: 0.5px solid $lights-high;

  @media (max-width: $breakpoint_s) {
    width: fit-content;
  }
}

.downloadAppWrapper {
  transform: scale(2);

  @media (max-width: $breakpoint_s) {
    transform: scale(1);
  }

  @media (min-width: $breakpoint_l) {
    margin: $spacing-2;
  }
  
}
