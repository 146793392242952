$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";
@import "styles2/scss/base";

.root {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  aspect-ratio: 16/9;
  //noinspection CssInvalidFunction
  transform: translateZ(0);
  overflow: hidden;

  &.withoutAspectRatio {
    aspect-ratio: auto;
  }

  &.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
  }
}

.debug {
  position: absolute;
  top: 10px;
  left: 10px;
  background: $black;
  padding: 10px;
  font-size: 8px;

  @media screen and (min-width: $breakpoint_m) {
    top: 20px;
    left: 20px;
    padding: 20px;
    font-size: 16px;
  }
}

// !important is necessary to fix a scss order issue with a loader component (build mode)
.loader {
  height: 100% !important;
  width: 100% !important;
  z-index: 1 !important;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoPoster {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: $black;
}

.controls {
  position: absolute;
  padding: 8px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  user-select: none;
  z-index: 2;

  @media screen and (min-width: $breakpoint_l) {
    padding: 12px 16px;
    justify-content: flex-start;
  }
}

.controlsBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.coverImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: $black;
  z-index: 1;
}

.placeholderWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.time {
  color: $white;

  @include text-sm-medium;

  @media screen and (min-width: $breakpoint_l) {
    @include text-base-regular;
  }
}
