$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

$track-color: rgba(51, 51, 51, 0.5);
$track-before-color: $harvest-gold;
$thumb-color: $harvest-gold;
$height: 4px;
$border-radius: 2px;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  z-index: 3;

  &:hover {
    .seekWrapper {
      display: flex;

      @media (hover: none) {
        display: none;
      }
    }
  }
}

.seekWrapper {
  position: absolute;
  bottom: calc(100% + 34px);
  padding: 14px 8px;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(51, 51, 51, 0.5);
  border: 1px solid rgba(102, 102, 102, 0.2);
  border-radius: 8px;
  transform: rotate(-90deg);

  // firefox only
  @-moz-document url-prefix() {
    padding: 8px 8px;
  }

  &::before {
    position: absolute;
    left: -6px;
    content: "";
    width: 100%;
    height: 30px;
  }
}

.seek {
  width: 80px;
  height: 100%;
  position: relative;
  border-radius: $border-radius;
  background-color: transparent;
  outline: none;
  appearance: none;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    width: var(--seek-before-width);
    height: $height;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background-color: $track-before-color;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $track-color;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    box-sizing: content-box;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    top: -7px;
    background-color: $white;
    border: 5px solid $thumb-color;
    cursor: pointer;
  }

  // Firefox
  &::-moz-range-track {
    width: 100%;
    height: $height;
    background: $track-color;
    overflow: hidden;
    border-radius: $border-radius;
    cursor: pointer;
  }

  &::-moz-range-progress {
    height: $height;
    background-color: $track-before-color;
    border-radius: $border-radius;
    cursor: pointer;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    appearance: none;
    box-sizing: content-box;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    top: -7px;
    background-color: $white;
    border: 5px solid $thumb-color;
    cursor: pointer;
  }
}
