$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  border-top: $border-default;
  padding: 0 $spacing-2;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-4;
  }
}

.linkButton {
  padding: 0 !important;
}

.body {
  width: 100%;
}
