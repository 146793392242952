$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

$mobile_main-component_top-padding: 40px;
$mobile_main-component_bottom-padding: 80px;
$desktop_footer_height: 308px;
$mobile_footer_height: 576px;

.main {
  position: relative;
  background-color: $background-dim;
  padding: $mobile_main-component_top-padding 0
    $mobile_main-component_bottom-padding;
  z-index: 3;
  overflow: visible;

  @each $category, $shades in $colors {
    @each $name, $value in $shades {
      &.backgroundColor-#{$category}-#{$name} {
        background-color: $value;
      }
    }
  }

  &.withFixedHeader {
    min-height: calc(100% - #{$mobile_footer_height});

    @media screen and (min-width: $breakpoint_l) {
      min-height: calc(100% - #{$desktop_footer_height});
    }
  }

  &.withProfileNavigation {
    display: flex;
    padding: 0;
  }

  &.withoutBottomPadding {
    padding-bottom: 0;
  }

  &.withoutSidesPadding {
    padding: 0;
    // use "withoutSidesPadding" as a flag for new redesigned pages
    font-family: "GT America";

    @media screen and (min-width: $breakpoint_l) {
      padding: 0;
    }
  }
}

.dashboardRoot {
  display: flex;
  justify-content: space-between;
  // max-width: $breakpoint_xxl;
  // margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: $header_height;
  }
}

.dashboardRootProfileUnderReview {
  @media screen and (min-width: $breakpoint_l) {
    margin-top: $header_height + 32px;
  }
}

.dashboardContent {
  padding: $spacing-4;
  background: $background-default;
  flex-grow: 1;
  width: 100%;
  margin: $header_height 0 0;

  @media screen and (min-width: $breakpoint_l) {
    padding: $spacing-6;
    background: transparent;
    margin: 0;
    // 32px but should be 40px. keep 32 to make old pages looks fine
    //padding: calc(#{$header_height} + 32px) 30px $desktop_gutter;
    //width: calc(100vw - (#{$profile-nav-width} + 32px));
  }

  [data-component="media-post-library"] {
    padding: 0;
  }
}
