$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

$icon-size: 41px;

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: $icon-size;
  border-radius: 4px;
  background: transparent;
  outline: none;
  flex-shrink: 0;

  &:hover,
  &:focus {
    background: transparent;
    cursor: pointer;
  }

  &.open {
    .icon {
      transform: rotate(0deg);
    }
  }

  @media screen and (orientation: landscape) {
    margin-right: 45px;
  }
}

.icon {
  position: absolute;
  right: 5px;
  transform: rotate(180deg);
}

.options {
  position: absolute;
  width: calc(100% + 20px);
  left: 0;
  bottom: $icon-size;
  padding: 4px 0;
  border-radius: 4px;
  background: rgba(51, 51, 51, 0.5);
  border: 1px solid rgba(102, 102, 102, 0.2);
  text-align: left;
  box-sizing: content-box;
}

.selectedValue,
.optionItem {
  font-weight: $bold_font_weight;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #c7c5c2;

  @include font_size(10px);
  @include line_height(12px);
}

.optionItem {
  padding: 2px 10px;
  outline: none;

  &:hover,
  &:focus {
    color: $white;
    cursor: pointer;
  }
}
