$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

$track-color: rgba(255, 255, 255);
$track-before-color: $harvest-gold;
$thumb-color: $harvest-gold;
$height: 4px;
$border-radius: 2px;
$buffered-color: #dddddd;

.root {
  margin: 0 12px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  @media screen and (min-width: $breakpoint_l) {
    margin: 0 24px;
  }
}
/*
* !important here is not a kludge. it's a common way to style the react-tooltip
* https://www.npmjs.com/package/react-tooltip
*/
.tooltip {
  background-color: transparent !important;
  box-shadow: none !important;
  opacity: 1 !important;

  &::after,
  &::before {
    display: none;
  }
}

.line {
  position: absolute;
  width: 100%;
  height: $height;
  background-color: $track-color;
  border-radius: $border-radius;
}

.seek {
  width: 100%;
  position: relative;
  border-radius: $border-radius;
  background-color: transparent;
  outline: none;
  overflow: hidden;
  appearance: none;
  margin-left: 0px;
  margin-right: 0px;

  // show slider thumb on hover
  &:hover {
    overflow: visible;

    &::-webkit-slider-thumb {
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      height: var(--seek-thumb-width);
      border-radius: 10px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: var(--seek-before-width);
    height: $height;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background-color: $track-before-color;
    cursor: pointer;
    z-index: 2;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    width: var(--seek-thumb-width);
    background: transparent;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    position: relative;
    top: -3px;
    appearance: none;
    box-sizing: content-box;
    height: 10px;
    width: 10px;
    border-radius: $border-radius;
    background-color: $thumb-color;
    cursor: pointer;
    z-index: 2;
  }

  // Firefox
  &::-moz-range-track {
    position: absolute;
    width: 100%;
    height: $height;
    cursor: pointer;
    background: transparent;
    overflow: hidden;
    z-index: 2;
  }

  &::-moz-range-progress {
    height: $height;
    background-color: $track-before-color;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    cursor: pointer;
    z-index: 2;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-moz-range-thumb {
    appearance: none;
    box-sizing: content-box;
    height: $height;
    width: var(--seek-thumb-width);
    border: none;
    border-radius: 0;
    background-color: $thumb-color;
    cursor: pointer;
    z-index: 2;
  }
}

.buffered {
  max-width: 100%;
  height: $height;
  position: absolute;
  background-color: $buffered-color;
  cursor: pointer;
  border-radius: $border-radius;
}
