$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}
