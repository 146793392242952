$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.socialLinks {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;
    width: 100%;
}
  
.socialLinksGrid {
    display: grid;
    gap: $spacing-4;
    width: 100%;
}
  
.socialLinkWrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
}
  
.socialLinkHeader {
    display: flex;
    align-items: center;
    gap: $spacing-3;
}
  
.socialLink {
    width: $spacing-6;
    height: $spacing-6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lights-low !important;
    background-color: $background-dim !important;
}

.label {
    padding-bottom: $spacing-3;
    color: $lights-low;
    @include text-body2Medium;
}

.extraMetaData {
    display: flex;
    flex-direction: column;
}

.profileUrl {
    color: $accent-default;
    text-decoration: none;
    word-break: break-all;

    @include text-body1Regular16;
    
    &:hover {
      text-decoration: underline;
    }
}
  