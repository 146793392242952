$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    flex-shrink: 0;
    position: sticky;
    height: 100%;
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 4;
    overflow: hidden;
    background-color: $background-dim;
    border-left: $border-1 solid $border-default;
    border-right: $border-1 solid $border-default;
  }
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
