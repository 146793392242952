$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  margin: 35px 0px;
  display: flex;
  flex-direction: column;
}

.title {
  display: block;
  margin: 0 29px;
  color: $silver-chalice;
  padding-bottom: 4px;
  color: $dove-gray;
  text-transform: capitalize;

  @include text-xs-medium-uppercase;
}

.list {
  margin-top: 10px;
}
