$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 7px;
  border-radius: 50%;
  background: transparent;
  border: none;
  flex-shrink: 0;
  outline: none;

  svg {
    color: $white;
    width: 24px;
    height: auto;
  }

  &:hover,
  &:focus {
    background: rgba(51, 51, 51, 0.95);
    cursor: pointer;
  }
}
