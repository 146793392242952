$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";
@import "styles3/scss/_base";

.mentionLabel {
  color: $lights-high;

  @include text-lg-medium;
}

.mentionList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 40px 0;
  gap: 10px;

  @media screen and (max-width: 600px) {
    overflow: auto;
  }
}

.mentionViewItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.mentionItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $background-light;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;
  padding: 4px 10px;
  min-height: 40px;

  &:hover,
  &:focus {
    background-color: $lights-high;
    & .mentionTitle {
      color: $background-dim;
    }
  }
}

.darkTheme {
  background-color: $background-light;

  & .mentionTitle {
    color: $lights-high;
  }
}

.hideAvatarImage {
  display: none;
}

.mentionTitle {
  color: $lights-high;
  word-wrap: break-word;

  @include text-base-regular;
}

.imageAvatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 20px;
}

.linkWrapper {
  display: flex;
  align-items: center;
}
