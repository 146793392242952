$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.listItem {
  padding: 3px 10px;

  &.active {
    .label,
    .icon {
      color: $lights-high;
    }

    .link {
      background: $background-light;
    }
  }
}

.link {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-radius: 5px;

  @extend %button-reset;
}

.icon {
  margin-right: 14px;
  margin-left: 2px;
  font-size: 20px;
  color: $lights-medium;
  flex-shrink: 0;
}

.arrowIcon {
  font-size: 18px;
  color: $lights-medium;
  flex-shrink: 0;
}

.label {
  margin-right: 12px;
  color: $lights-medium;
  flex-grow: 1;
  text-align: left;

  @include text-subtitle2;
}

.subMenu {
  margin-top: 10px;
}

.subMenuListItem {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.subMenuLink {
  display: block;
  padding: 0 29px 0 65px;
  width: 100%;
  color: $lights-medium;

  @include text-subtitle2;
}
